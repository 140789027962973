<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_operator_report') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.organization')"
                    label-for="org_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.org_id"
                    :options="organizationtList"
                    id="org_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro_division.division')"
                    label-for="division_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.division_id"
                    :options="divisionList"
                    id="division_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('org_pro_district.district')"
                        label-for="district_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="districtList"
                        id="district_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('org_pro_upazilla.upazilla')"
                        label-for="upazilla_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazilaList"
                        id="upazilla_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t($t('org_pro_union.union'))"
                        label-for="union_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.union_id"
                        :options="unionList"
                        id="union_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('externalUserIrrigation.village')"
                        label-for="village_name"
                        >
                        <b-form-input
                            id="village_name"
                            v-model="search.village_name"
                            placeholder=""
                            ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('pump_install.name')"
                        label-for="village_name"
                        >
                        <b-form-input
                            id="name"
                            v-model="search.name"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="6">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_operator_report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                    <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                </b-button>
                <export-excel
                    class="btn btn_add_new"
                    :data="dataCustomize"
                    :fields= "json_fields"
                    :title="$t('irriPumpInformation.pump_operator_report')"
                    worksheet="Pump Operator Report Sheet"
                    name="Pump Operator Report Sheet.xls">
                    <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="irriSchemeServiceBaseUrl"
                                          uri="report-heading/detail"
                                          :org-id="search.org_id"
                                          :key="reloadListHeading"
                                          :loading-state="loadingState">
                          {{ $t('irriPumpInformation.pump_operator_report')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="pumpReportList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      <span>{{ data.item.village_name_bn + ', ' + data.item.union_name_bn + ', ' + data.item.upazilla_name_bn + ', ' + data.item.district_name_bn + ', ' + data.item.division_name_bn }}</span>
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span>{{ data.item.village_name + ', ' + data.item.union_name + ', ' + data.item.upazilla_name + ', ' + data.item.district_name + ', ' + data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(latitude)="data">
                      <b-button v-if="data.item.latitude !== ''" variant="primary" v-b-modal.modal-map @click="mapModalOpen(data.item)">
                        Geo Location Link
                      </b-button>
                    </template>
                    <template v-slot:cell(mobile_no)="data">
                      {{ data.item.mobile_no | mobileNumber }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-map" size="lg" :title="mapTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <MapModal :latitude="latitude" :longitude="longitude"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import HierarchycalNames from '@/Utils/common'
import { pumpOperatorReportList } from '../../api/routes'
import MapModal from './MapModal'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { mapGetters } from 'vuex'
Vue.use(excel)

const excelColumn = {
  Organization: 'org',
  'Operator Location': 'division'
}
const excelColumnBn = {
  সংস্থা: 'org',
  'অপারেটরের অবস্থান': 'division'
}
export default {
    name: 'UiDataTable',
    components: {
        MapModal,
        ListReportHead
    },
    data () {
        return {
            noDataFound: false,
            pagination: {
                currentPage: 1,
                totalRows: 0,
                slOffset: 1
            },
            showData: false,
            search: {
                org_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                union_id: 0,
                name: '',
                village_name: ''
            },
            testId: 0,
            latitude: 0,
            longitude: 0,
            organization: {},
            pumpReportList: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            rows: [],
            irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
            reportHead: null,
            reloadListHeading: 1
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            const pumpReportList = this.pumpReportList
            pumpReportList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    item.org = item.org_name_bn
                    item.division = item.village_name_bn + ', ' + item.union_name_bn + ', ' + item.upazilla_name_bn + ',' + item.district_name_bn + ',' + item.division_name_bn
                } else {
                    item.org = item.org_name
                    item.division = item.village_name + ', ' + item.union_name + ', ' + item.upazilla_name + ', ' + item.district_name + ', ' + item.division_name
                }
                return Object.assign({}, item)
            })
            return pumpReportList
        },
        mapTitle () {
            return this.$t('irriPumpInformation.mapTitle')
        },
        organizationtList: function () {
            const listObject = this.$store.state.commonObj.organizationProfileList
            return listObject
        },
        divisionList: function () {
            const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
            return divisions.map((item) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
                } else {
                    return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
                }
            })
        },
        loading () {
            return this.$store.state.commonObj.loading
        },
        listReload () {
            return this.$store.state.commonObj.listReload
        },
        loadingState () {
            if (this.listReload) {
                return true
            } else if (this.loading) {
                return true
            } else {
                return false
            }
        },
        currentLocale () {
            return this.$i18n.locale
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('irriPumpInformation.operator_name'), class: 'text-left' },
                { label: this.$t('irriPumpInformation.mobile'), class: 'text-left' },
                { label: this.$t('irriPumpInformation.operator_location'), class: 'text-left' },
                { label: this.$t('irriPumpInformation.pump_geo_location'), class: 'text-left' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'name_bn' },
                { key: 'mobile_no' },
                { key: 'division_name_bn' },
                { key: 'latitude' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'name' },
                { key: 'mobile_no' },
                { key: 'division_name' },
                { key: 'latitude' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'search.district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'search.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
        },
        currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.district_id) {
              this.upazilaList = this.getUpazilaList()
            }
            if (this.search.upazilla_id) {
              this.unionList = this.getUnionList()
            }
        }
    }
    },
    mounted () {
        core.index()
            if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.office_detail.org_id
                })
            } else if (this.authUser.role_id === 0) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.office_detail.org_id,
                    division_id: this.authUser.office_detail.division_id,
                    district_id: this.authUser.office_detail.district_id,
                    upazilla_id: this.authUser.office_detail.upazilla_id
                })
            }
    },
    methods: {
        mapModalOpen (item) {
            this.latitude = item.latitude
            this.longitude = item.longitude
        },
        resetId () {
            this.testId = 0
        },
        default () {
        return {
            id: this.rows.length,
            name: '',
            name_bn: ''
        }
        },
        searchData () {
            this.reloadListHeading++
            if (this.search.org_id !== 0) {
                this.loadData()
            } else {
                this.showData = false
            }
        },
        async loadData () {
            this.$store.commit('mutateCommonProperties', { loading: true })
            await RestApi.getData(irriSchemeServiceBaseUrl, pumpOperatorReportList, this.search).then(response => {
                if (response.success) {
                    this.dataList(response)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        dataList (data) {
            if (data.data.length !== 0) {
                this.showData = true
            }
            const organizationList = this.$store.state.commonObj.organizationProfileList
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const unionList = this.$store.state.commonObj.unionList
            const pumpOperatorData = data.data.map(item => {
                const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
                const divisionObject = divisionList.find(division => division.value === item.division_id)
                const districtObject = districtList.find(district => district.value === item.district_id)
                const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
                const unionObject = unionList.find(union => union.value === item.union_id)
                const orgData = {
                org_name: orgObject.text,
                org_name_bn: orgObject.text_bn
                }
                const divisionData = {
                division_name: divisionObject !== undefined ? divisionObject.text : '',
                division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
                }
                const districtData = {
                district_name: districtObject !== undefined ? districtObject.text : '',
                district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
                }
                const upazillaData = {
                upazilla_name: upazilaObject !== undefined ? upazilaObject.text : '',
                upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
                }
                const unionData = {
                union_name: unionObject !== undefined ? unionObject.text : '',
                union_name_bn: unionObject !== undefined ? unionObject.text_bn : ''
                }
            return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData)
        })
            this.pumpReportList = pumpOperatorData
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazillaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazillaId) {
                return unionList.filter(union => union.upazilla_id === upazillaId)
            }
            return unionList
        },
        pdfExport () {
            const rowData = this.getPdfData()
            const labels = [
                { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('user.name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
                { text: this.$t('irriPumpInformation.operator_location'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
            ]
            rowData.unshift(labels)
            const reportTitle = this.$i18n.locale === 'en' ? 'Pump Operator Report' : 'পাম্প অপারেটর রিপোর্ট'
            const columnWids = [100, '30%', '*']
            ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
        },
        getPdfData () {
            const keys = [
                { key: 'serial_no' },
                { key: this.$i18n.locale === 'en' ? 'name' : 'name_bn' },
                { key: 'operator_location' }
            ]
            var serial = 0
            const listData = this.pumpReportList.map(item => {
                const allNames = HierarchycalNames.getCommonHierarchyNames(item.union_id, 'union')
                const villageName = this.$i18n.locale === 'en' ? item.village_name : item.village_name_bn
                serial += 1
                const rowData = keys.map((keyItem, index) => {
                    if (keyItem.key === 'operator_location') {
                        return { text: `${villageName}, ${allNames.unionName}, ${allNames.upazilaName}, ${allNames.districtName}, ${allNames.divisionName}` }
                    }

                    if (keyItem.key === 'serial_no') {
                        return { text: this.$n(serial) }
                    }

                return { text: item[keyItem.key] }
                })

                return rowData
            })

            return listData
        }
    }
}
</script>
